import { Typography } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Applications } from '../../@types/configuration';
import TealiumDataLayer from '../../components/hocs/tealium-data-layer';
import ExecutionHistoryDetailsCardComponent from '../../components/shared/execution/history-details-card';
import { Features } from '../../config/features';
import { WithFeaturesProxy } from '../../helpers/with-features-proxy';
import { ProcessorExecutionRequest } from '../../interface/processor';
import useExecutions from '../../resources/executions/executions-hook';
import { ExecutionDetails, ProcessorLog } from '../../resources/history-list/history-list-types';
import useProcessors from '../../resources/processors/processors-hook';
import useToasts from '../../resources/toasts/toasts-hook';
import { toISOString } from '../../utils/date-utils';
import './index.scss';
import TestingToolFormComponent, { Values } from './testing-tool-form';

const formatExecutionDetailsInputJson = (details: ExecutionDetails) => {
  const firstLevelParse = JSON.parse(details.execution.input ?? '{}');

  if ('rawMessage' in firstLevelParse) {
    return { ...firstLevelParse, rawMessage: JSON.parse(firstLevelParse.rawMessage) };
  }

  return firstLevelParse;
};

interface Props {
  mktHasSharedId: boolean;
}

function TestingToolComponent({ mktHasSharedId }: Props) {
  const { search } = useLocation();
  const {
    createExecution,
    createExecutionResponse,
    fetchExecutionDetails,
    executionDetails,
    resetExecution,
    fetchExecutionCompletedSteps,
  } = useExecutions();
  const { fetchProcessorSteps, processors } = useProcessors();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const executionDetailsRef = useRef<HTMLDivElement | null>(null);
  const [execution, setExecution] = useState<ProcessorLog | null>();
  const [executingTest, setExecutingTest] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<Values>({
    message: '',
    email: '',
    language: '',
    phone: '',
    processor: { label: '', id: 0 },
    country: '',
  });
  const [prettyJsonInitialValue, setPrettyJsonInitialValue] = useState();
  const processorLogRequest = useMemo(
    () => ({
      executionId: searchParams.get('executionId') ?? undefined,
      sharedId: searchParams.get('sharedId') ?? undefined,
      application: (searchParams.get('application') as Applications) ?? undefined,
      processorName: searchParams.get('processorName') ?? undefined,
      startDt: searchParams.get('startDt') ?? undefined,
      endDt: searchParams.get('endDt') ?? undefined,
    }),
    [searchParams],
  );
  const isProcessorLogRequestSet = useMemo(
    () => Object.values(processorLogRequest).some(isDefined => !!isDefined),
    [processorLogRequest],
  );
  const [retriesAttempt, setRetriesAttempt] = useState(0);
  const MAX_RETRIES_ATTEMPTS = 40;
  const INTERVAL_BETWEEN_ATTEMPTS = 5000;
  const { push } = useToasts();

  useEffect(() => {
    return () => resetExecution();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isProcessorLogRequestSet && executionDetails.data) {
      const formattedJSON = formatExecutionDetailsInputJson(executionDetails.data);
      setPrettyJsonInitialValue(formattedJSON.rawMessage ?? '');
      setFormFields(prev => ({
        ...prev,
        processor: {
          label: executionDetails.data?.execution.processorName ?? '',
          id: processors.data?.find(proc => proc.name === executionDetails.data?.execution.processorName)?.id ?? 0,
        },
        message: formattedJSON.rawMessage ?? '',
      }));
      setExecutingTest(false);
    }
  }, [executionDetails.data, isProcessorLogRequestSet, processors.data]);

  const handleValuesChange = (newValues: Values) => {
    setFormFields(newValues);
  };

  const handleSubmit = () => {
    sendMessage();
  };

  const sendMessage = useCallback(() => {
    // console.log('1');
    resetExecution();
    setExecutingTest(true);
    setExecution(null);
    const request: ProcessorExecutionRequest = {
      payload: JSON.stringify(formFields.message),
      processorName: formFields.processor.label,
      overwriteEmail: formFields.email,
      overwritePhone: formFields.phone,
      overwriteLanguage: formFields.language,
      overwriteCountry: formFields.country,
    };
    fetchProcessorSteps(Number(formFields.processor.id));
    createExecution(request);
  }, [
    createExecution,
    fetchProcessorSteps,
    formFields.country,
    formFields.email,
    formFields.language,
    formFields.message,
    formFields.phone,
    formFields.processor.id,
    formFields.processor.label,
    resetExecution,
  ]);

  useEffect(() => {
    if (createExecutionResponse.data !== undefined) {
      // console.log('2');
      const { executionId, processor } = createExecutionResponse.data;
      const date = new Date();
      const execution = {
        executionId: executionId,
        // sharedId: mktHasSharedId ? executionId : undefined,
        processorName: processor ?? formFields.processor.label,
        startDt: toISOString(date),
        endDt: toISOString(date),
      };

      setExecution(execution);
    }
  }, [createExecutionResponse.data, formFields.processor, mktHasSharedId]);

  const getExecutionDetails = useCallback(
    (execution: ProcessorLog) =>
      new Promise<void>(resolve => {
        if (!executionDetails.data) {
          // console.log('4');
          const fetchDetails = fetchExecutionDetails(execution);
          const fetchCompletedSteps = fetchExecutionCompletedSteps(String(execution.executionId));
          Promise.all([fetchDetails, fetchCompletedSteps]).then(() => resolve());
        }
      }),
    [executionDetails.data, fetchExecutionCompletedSteps, fetchExecutionDetails],
  );

  useEffect(() => {
    if (execution && retriesAttempt <= MAX_RETRIES_ATTEMPTS) {
      // console.log('5');
      setTimeout(() => {
        getExecutionDetails(execution).then(() => setRetriesAttempt(prev => prev + 1));
      }, INTERVAL_BETWEEN_ATTEMPTS);
    } else if (retriesAttempt >= MAX_RETRIES_ATTEMPTS) {
      setExecutingTest(false);
      push('Execution details not found.', 'error');
    }
  }, [execution, getExecutionDetails, push, retriesAttempt]);

  useEffect(() => {
    if (execution) {
      // console.log('3');
      getExecutionDetails(execution);
    }
  }, [execution, getExecutionDetails]);

  useEffect(() => {
    if (execution && executionDetailsRef?.current) {
      executionDetailsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [execution, executionDetailsRef]);

  return (
    <TealiumDataLayer
      page_name="Testing Tool"
      page_section="testing-tool"
      page_category="Analytics"
      page_subCategory="Processor and Messaging Tester">
      <Container className="testing-tool">
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Typography variant="heading">Testing Tool</Typography>
                <Typography weight="bold" color="text-gray" className="mt-3">
                  Select your processor, message and edit the sample below.
                </Typography>
              </Col>
            </Row>
            <TestingToolFormComponent
              executingTest={executingTest}
              values={formFields}
              prettyJsonInitialValue={prettyJsonInitialValue}
              onValuesChange={handleValuesChange}
              onSubmit={handleSubmit}
            />
          </Card.Body>
        </Card>
        {execution && (
          <Row>
            <ExecutionHistoryDetailsCardComponent
              ref={executionDetailsRef}
              execution={execution}
              details={executionDetails.data}
            />
          </Row>
        )}
      </Container>
    </TealiumDataLayer>
  );
}

export default WithFeaturesProxy(Features.MktFeature_HasSharedId)((props, ...hasFeatures) => {
  const [mktHasSharedId] = hasFeatures;

  return <TestingToolComponent {...props} mktHasSharedId={mktHasSharedId} />;
});
