import { useCallback, useState } from 'react';
import { ExternalData } from '../../@types/external-api';
import { Features } from '../../config/features';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataInitialKeepData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import useExternalApiErrorHandler from '../../hooks/use-external-api-error-handler';
import useFeatures from '../features/features-hook';
import useProcessors from '../processors/processors-hook';
import ExecutionsContext, { ExecutionsCtxType } from './executions-context';
import {
  CreateProcessorExecutionResponse,
  ExecutionDetails,
  ExecutionsResponse,
  ProcessorStepLog,
} from './executions-types';
import executionsService from './executions.service';

const ExecutionProvider: React.FC = ({ children }) => {
  const errorHandler = useExternalApiErrorHandler();
  const [createExecutionResponse, setCreateExecutionResponse] = useState<
    ExternalData<CreateProcessorExecutionResponse>
  >(makeExternalDataInitialData());
  const [executionDetails, setExecutionDetails] = useState<ExternalData<ExecutionDetails>>(
    makeExternalDataInitialData(),
  );
  const [executions, setExecutions] = useState<ExternalData<ExecutionsResponse>>(makeExternalDataInitialData());
  const [executionCompletedSteps, setExecutionsCompletedSteps] = useState<ExternalData<ProcessorStepLog[]>>(
    makeExternalDataInitialData(),
  );
  const [exceptions, setExceptions] = useState<ExternalData<ExecutionsResponse>>(makeExternalDataInitialData());
  const { hasFeature } = useFeatures();
  const { processorsWithEmail, processors } = useProcessors();

  const resetExecution = () => {
    setCreateExecutionResponse(makeExternalDataInitialData());
    setExecutionDetails(makeExternalDataInitialData());
    setExecutionsCompletedSteps(makeExternalDataInitialData());
  };

  const createExecution: ExecutionsCtxType['createExecution'] = async req => {
    try {
      resetExecution();
      let data;
      // create a new event via new PublishAPI or KAFKA
      if (hasFeature(Features.MktFeature_UsesPublishAPI)) {
        data = await executionsService.createOnPublisherApi(req);
      } else {
        data = await executionsService.create(req);
      }
      setCreateExecutionResponse(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setCreateExecutionResponse(makeExternalCallErrorData(err));
    }
  };

  const fetchExecutions: ExecutionsCtxType['fetchExecutions'] = useCallback(
    req => {
      const processorHasEmailPreview = (req.processor ?? (processors.data ?? []).map(proc => proc.name) ?? []).some(
        processor => processorsWithEmail.includes(processor),
      );

      const { promise: promiseWithEmail } = executionsService.getExecutionHistory(req, true);

      const { abort, promise } = executionsService.getExecutionHistory(req, false);

      setExecutions(prev => makeExternalDataInitialKeepData(prev, abort));

      const getResponseWithoutEmail = promise
        .then(data => {
          setExecutions(makeExternalDataSuccessData(data));
        })
        .catch(err => {
          setExecutions(makeExternalCallErrorData(err));
          errorHandler(err, {
            cancel: () => {
              console.log('axios request cancelled', err.message);
              setExecutions(makeExternalDataInitialData());
            },
          });
        });

      const getResponseWithEmail = promiseWithEmail
        .then(data => {
          return makeExternalDataSuccessData(data);
        })
        .catch(err => {
          return makeExternalCallErrorData(err);
        });

      // The first Promise.all is to improve performance by getting without email and then substituting by the response with the emails
      if (processorHasEmailPreview) {
        Promise.all([getResponseWithoutEmail, getResponseWithEmail]).then(response => {
          setExecutions(response[1]);
        });
      } else {
        Promise.all([getResponseWithoutEmail]);
      }

      return abort;
    },
    [errorHandler, processors.data, processorsWithEmail],
  );

  const fetchExceptions: ExecutionsCtxType['fetchExecutions'] = useCallback(
    req => {
      const { abort, promise } = executionsService.getExecutionHistory(req, false);

      setExceptions(prev => makeExternalDataInitialKeepData(prev, abort));

      promise
        .then(data => {
          setExceptions(makeExternalDataSuccessData(data));
        })
        .catch(err => {
          setExceptions(makeExternalCallErrorData(err));
          errorHandler(err, {
            cancel: () => {
              console.log('axios request cancelled', err.message);
              setExceptions(makeExternalDataInitialData());
            },
          });
        });

      return abort;
    },
    [errorHandler],
  );

  const fetchExecutionDetails: ExecutionsCtxType['fetchExecutionDetails'] = useCallback(async req => {
    try {
      setExecutionDetails(makeExternalDataInitialData());
      const data = await executionsService.getExecutionDetails(req);
      setExecutionDetails(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setExecutionDetails(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchExecutionCompletedSteps = useCallback(async (executionId: string) => {
    try {
      const data = await executionsService.getExecutionCompletedSteps(executionId);
      setExecutionsCompletedSteps(makeExternalDataSuccessData(data));
    } catch (err: any) {
      setExecutionDetails(makeExternalCallErrorData(err));
    }
  }, []);

  return (
    <ExecutionsContext.Provider
      value={{
        createExecutionResponse,
        executions,
        exceptions,
        executionDetails,
        executionCompletedSteps,
        createExecution,
        fetchExecutions,
        fetchExceptions,
        fetchExecutionDetails,
        fetchExecutionCompletedSteps,
        resetExecution,
      }}>
      {children}
    </ExecutionsContext.Provider>
  );
};

export default ExecutionProvider;
