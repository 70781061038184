import { mdiAutorenew, mdiEmailOutline, mdiLoading, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Page } from '../../../@types/page';
import IconButton from '../../../components/ui/icon-btn';
import PaginatedTableComponent, { Column, SelectedRowsDict } from '../../../components/ui/paginated-table';
import { Features } from '../../../config/features';
import { WithFeaturesProxy } from '../../../helpers/with-features-proxy';
import { ExecutionResponse, ExecutionStatus, ProcessorLog } from '../../../resources/history-list/history-list-types';
import { getStatusColor, getStatusIconPath, shortenUUID } from '../../../utils/execution-utils';

interface ExposedProps {
  response?: ExecutionResponse | null;
  selectedPage: number;
  setSelectedPage: (page: number) => void;
  limit?: number;
  timedout: boolean;
  error: boolean;
  loadPage: (page: Page) => Promise<ExecutionResponse | void>;
  onDetailsClick: (execution: ProcessorLog) => void;
  onEmailPreviewClick: (execution: ProcessorLog) => void;
  onRetryClick: (execution: ProcessorLog) => void;
  onBatchRetryClick: (execution: ProcessorLog[]) => void;
  makeNavLink?: (execution: ProcessorLog) => string;
}

interface Props extends ExposedProps {
  canSeeDetails: boolean;
  canReprocess: boolean;
  mktHasEmailPreview: boolean;
  mktShowsUserId: boolean;
  mktHasResendEmail: boolean;
}

const ExecutionHistoryListComponent: React.FC<Props> = props => {
  const {
    response,
    selectedPage,
    setSelectedPage,
    timedout = false,
    error = false,
    limit = 10,
    loadPage,
    onDetailsClick,
    onEmailPreviewClick,
    onRetryClick,
    onBatchRetryClick,
    canReprocess,
    canSeeDetails,
    makeNavLink,
    mktHasEmailPreview,
    mktShowsUserId,
    mktHasResendEmail,
  } = props;
  const [selected, setSelected] = useState<SelectedRowsDict>({});
  const [reprocessLabel] = useState<string>(mktHasResendEmail ? 'Resend' : 'Reprocess');
  const [columns] = useState<Column[]>(
    (
      [
        {
          id: 'status',
          label: 'Status',
          getIcon: (row: any) => {
            const status: ExecutionStatus | undefined = row.status;

            if (status) {
              const statusIconPath = getStatusIconPath(status);
              const statusColor = getStatusColor(status);

              return (
                <Icon
                  path={statusIconPath!}
                  title="Status"
                  size="16px"
                  horizontal
                  vertical
                  rotate={180}
                  color={statusColor}
                  style={{ verticalAlign: 'text-bottom', marginRight: '8px' }}
                />
              );
            }
          },
        },
        {
          id: 'executionId',
          label: 'Execution ID',
          format: (value: string) => shortenUUID(value),
        },
        {
          id: 'aboNumber',
          label: 'ABO Number',
        },
        mktShowsUserId ? { id: 'userId', label: 'User ID' } : null,
        {
          id: 'processorName',
          label: 'Processor',
        },
        {
          id: 'endDt',
          alternativeId: 'startDt',
          label: 'Date (GMT)',
          format: (value: string) => new Date(value).toLocaleString(),
        },
        canReprocess
          ? {
              id: mktHasResendEmail ? 'resend' : 'reprocess',
              maxWidth: 1.5,
              getIcon: (row: ProcessorLog) => (
                <IconButton
                  mdiIconPath={mdiAutorenew}
                  onClick={() => onRetryClick(row)}
                  disabled={row.resending}
                  spin={row.resending}
                  color={row.resending ? 'var(--detail-gray)' : 'var(--primary)'}
                  title={`${reprocessLabel} email`}
                  size="18px"
                />
              ),
            }
          : null,
        mktHasEmailPreview
          ? {
              id: 'preview',
              maxWidth: 1.5,
              getIcon: (row: ProcessorLog) => (
                <IconButton
                  mdiIconPath={row.hasEmailPreview === undefined ? mdiLoading : mdiEmailOutline}
                  onClick={() => onEmailPreviewClick(row)}
                  disabled={!row.hasEmailPreview}
                  spin={row.hasEmailPreview === undefined}
                  color={!row.hasEmailPreview ? 'var(--detail-gray)' : 'var(--primary)'}
                  title="Preview processed email"
                  size="18px"
                />
              ),
            }
          : null,
        canSeeDetails
          ? {
              id: 'details',
              maxWidth: 1.5,
              getIcon: (row: ProcessorLog) => {
                return makeNavLink && row.status !== 'PROCESSING' ? (
                  <Link to={makeNavLink(row)}>
                    <Icon path={mdiOpenInNew} color="var(--primary)" title="Execution details" size="18px" />
                  </Link>
                ) : (
                  <IconButton
                    mdiIconPath={mdiOpenInNew}
                    onClick={() => onDetailsClick(row)}
                    disabled={row.status === 'PROCESSING'}
                    color="var(--primary)"
                    title="Execution details"
                    size="18px"
                  />
                );
              },
            }
          : null,
      ] as Column[]
    ).filter(column => column),
  );

  const handleBatchRetryClick = useCallback(() => {
    const executions = Object.values(selected);
    onBatchRetryClick(executions);
  }, [onBatchRetryClick, selected]);

  const rows = useMemo(() => {
    if (response === undefined) {
      return undefined;
    } else if (response === null) {
      return null;
    }

    return response.executions;
  }, [response]);

  return (
    <PaginatedTableComponent
      columns={columns}
      rows={rows}
      rowIdPropName="executionId"
      selectedPage={selectedPage}
      setSelectedPage={setSelectedPage}
      selectable={true}
      selected={selected}
      setSelected={setSelected}
      selectedDisplayMessage="execution(s) selected"
      selectedActionBtnOnClick={handleBatchRetryClick}
      selectedActionBtnTxt={`${reprocessLabel} Execution(s)`}
      selectedRowsPerPage={limit}
      totalCount={response?.total ?? rows?.length ?? Number.MAX_SAFE_INTEGER}
      loadPage={loadPage}
      timedout={timedout}
      error={error}
      noResultsHeader="No Executions Found"
      noResultsDescription="Check the fields in the filter and try again!"
      sx={{ maxWidth: 'calc(100vw - 104px - 2*24px - 32px - 38px - 19px)' }}
    />
  );
};

export default WithFeaturesProxy<ExposedProps>(
  Features.ReprocessEmail,
  Features.ExecutionHistoryScreen_Details,
  Features.MktFeature_EmailPreview,
  Features.MktFeature_ShowUserIdOnExecutionDetails,
  Features.MktFeature_ResendEmail,
)((props, ...hasFeatures) => {
  const [hasReprocessFeature, hasDetailsFeature, mktHasEmailPreview, mktShowsUserId, mktHasResendEmail] = hasFeatures;

  return (
    <ExecutionHistoryListComponent
      {...props}
      canSeeDetails={hasDetailsFeature}
      canReprocess={hasReprocessFeature}
      mktHasEmailPreview={mktHasEmailPreview}
      mktShowsUserId={mktShowsUserId}
      mktHasResendEmail={mktHasResendEmail}
    />
  );
});
